import Cookies from 'js-cookie';
import { env, isVercelPreview } from 'src/env.mjs';

export function setAuthCookies(token: string, uuid: string) {
    const cookieDomain = isVercelPreview
        ? undefined
        : process.env.NEXT_PUBLIC_COOKIE_DOMAIN;

    Cookies.set(env.NEXT_PUBLIC_COOKIE_PREFIX + 'mosh_ut_v2', token, {
        expires: 4 / 24,
        domain: cookieDomain,
    });
    Cookies.set(env.NEXT_PUBLIC_COOKIE_PREFIX + 'mosh_uuid', uuid, {
        expires: 4 / 24,
        domain: cookieDomain,
    });
}

// TODO: Move to shared or fix the backend or something
import { camelCase, isArray, transform, isObject } from 'lodash';

export const camelizeObjectKeys = (obj: Record<string, unknown>) =>
    transform(
        obj,
        (
            result: Record<string, unknown>,
            value: unknown,
            key: string,
            target,
        ) => {
            const camelKey = isArray(target) ? key : camelCase(key);
            result[camelKey] = isObject(value)
                ? camelizeObjectKeys(value as Record<string, unknown>)
                : value;
        },
    );

export const validateEmail = (email: string) => {
    if (
        email.match(
            // eslint-disable-next-line no-control-regex
            /(?:[a-z0-9+!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi,
        )
    ) {
        return true;
    }

    return false;
};

export const addPercentage = (value: number, percentage: number) => {
    return Math.round(value + value * (percentage / 100));
};

export const formatPhoneNumber = (input: string | number) => {
    const number = input.toString();
    return number.replace(/(\d{4})(\d{0,3})(\d{0,3})/, (_, p1, p2, p3) =>
        [p1, p2, p3].filter(Boolean).join(' '),
    );
};

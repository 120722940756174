import { getHeaders, useToast } from '@get-mosh/msh-shared';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSearchParams } from 'next/navigation';
import ApiRoutes from 'src/constants/ApiRoutes';
import Routes from 'src/constants/Routes';
import { isVercelPreview } from 'src/env.mjs';
import { setAuthCookies } from 'src/helpers/auth';
import { ApiError } from 'src/types/Api';

export type LoginResponse = {
    uuid: string;
    accessToken: string;
    refreshToken: string;
};

type LoginRequest = {
    identity: string;
    password: string;
};

const useLoginSuccess = (leadForm = false) => {
    const queryClient = useQueryClient();
    const searchParams = useSearchParams();

    return (response: LoginResponse) => {
        setAuthCookies(response.accessToken, response.uuid);

        queryClient.invalidateQueries({
            queryKey: ['User'],
        });

        if (!leadForm) {
            const redirectUrl = searchParams.get('redirect_url');

            window.location.assign(
                redirectUrl
                    ? redirectUrl
                    : isVercelPreview
                      ? Routes.HOME
                      : Routes.NOTIFICATIONS,
            );
        }
    };
};

export type AppleAuthenticationRequest = {
    quizToken: string | undefined;
    identityToken: string;
    authorizationCode: string;
    fullName?: {
        givenName: string;
        familyName: string;
    };
};

async function postAppleLogin(
    request: AppleAuthenticationRequest,
): Promise<LoginResponse> {
    const res = await fetch(ApiRoutes.APPLE_LOGIN, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(request),
    });
    const data = await res.json();

    if (!res.ok) {
        throw data as ApiError;
    }

    return data as LoginResponse;
}

export const useLoginWithApple = (leadForm = false) => {
    const loginSuccess = useLoginSuccess(leadForm);

    return useMutation<LoginResponse, ApiError, AppleAuthenticationRequest>({
        mutationKey: ['AppleLogin'],
        mutationFn: (request: AppleAuthenticationRequest) =>
            postAppleLogin(request),
        onSuccess: loginSuccess,
    });
};

type LoginWithGoogleRequest = {
    quizToken: string | undefined;
    idToken: string;
};

async function postGoogleLogin(
    request: LoginWithGoogleRequest,
): Promise<LoginResponse> {
    const res = await fetch(ApiRoutes.GOOGLE_LOGIN, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(request),
    });
    const data = await res.json();

    if (!res.ok) {
        throw data as ApiError;
    }

    return data as LoginResponse;
}

export const useLoginWithGoogle = (leadForm = false) => {
    const loginSuccess = useLoginSuccess(leadForm);

    return useMutation<LoginResponse, ApiError, LoginWithGoogleRequest>({
        mutationKey: ['GoogleLogin'],
        mutationFn: (request: LoginWithGoogleRequest) =>
            postGoogleLogin(request),
        onSuccess: loginSuccess,
    });
};

async function postLogin(request: LoginRequest): Promise<LoginResponse> {
    const res = await fetch(ApiRoutes.LOGIN, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(request),
    });
    const data = await res.json();

    if (!res.ok) {
        throw data as ApiError;
    }
    return data as LoginResponse;
}

export const useLogin = (leadForm = false) => {
    const loginSuccess = useLoginSuccess(leadForm);
    const { showToast } = useToast();

    return useMutation<LoginResponse, ApiError, LoginRequest>({
        mutationKey: ['Login'],
        mutationFn: (request: LoginRequest) => postLogin(request),
        onError: (error) => {
            const response = error as ApiError;
            if (response.type === 'user_authentication_failure') {
                showToast({
                    title: 'Incorrect email or password',
                    message: 'Please check your details and try again',
                    variant: 'error',
                });
            } else {
                showToast({
                    title: 'An error occurred',
                    message: 'Please try again later',
                    variant: 'error',
                });
            }
        },
        onSuccess: loginSuccess,
    });
};

import classNames from 'classnames';
import MoshLogo from '../MoshLogo';
import Routes from 'src/constants/Routes';
import { useEffect, useRef } from 'react';
import { isMosh } from 'src/env.mjs';
import MoshyLogo from '../MoshyLogo';

const Header = () => {
    const navigationRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const navigationHeight = navigationRef.current?.clientHeight;
        document
            .querySelector('html')
            ?.style.setProperty('--header-height', navigationHeight + 'px');
    }, []);

    return (
        <header
            id="mainHeader"
            className={classNames('z-100 relative bg-white')}
            data-header="container"
        >
            <div className="relative">
                <section
                    id="headroom"
                    style={{ height: 'var(--header-height)' }}
                    ref={navigationRef}
                >
                    <div
                        className={classNames(
                            'flex items-center justify-center p-4 md:container',
                        )}
                        data-header="navigation"
                    >
                        {isMosh ? (
                            <MoshLogo link={Routes.HOME} label="Home" />
                        ) : (
                            <MoshyLogo link={Routes.HOME} label="Home" />
                        )}
                    </div>
                </section>
            </div>
        </header>
    );
};

export default Header;

import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import { useLoginWithGoogle } from 'src/hooks/User/useLogin';

type GoogleLoginButtonProps = {
    quizToken?: string;
    leadForm?: boolean;
};

function GoogleLoginButton({
    quizToken,
    leadForm = false,
}: GoogleLoginButtonProps) {
    const { mutateAsync } = useLoginWithGoogle(leadForm);

    const onSuccess = async (response: CredentialResponse) => {
        if (!response.credential) {
            throw new Error('Google login failed');
        }
        await mutateAsync({
            idToken: response.credential,
            quizToken: quizToken,
        });
    };

    return (
        <>
            <div className="hidden sm:block" data-button="Google sign in">
                <GoogleLogin
                    text="continue_with"
                    logo_alignment="center"
                    size="large"
                    width="400px"
                    onSuccess={onSuccess}
                />
            </div>
            <div className="sm:hidden" data-button="Google sign in">
                <GoogleLogin
                    text="continue_with"
                    logo_alignment="center"
                    size="large"
                    width="300px"
                    onSuccess={onSuccess}
                />
            </div>
        </>
    );
}

export default GoogleLoginButton;

import React from 'react';
import Page from 'src/components/Page/Page';
import Header from 'src/components/Header/Header';

type LayoutBasicProps = {
    className?: string;
    children: React.ReactNode;
};

const LayoutBasic = ({ className, children }: LayoutBasicProps) => {
    return (
        <React.Fragment>
            <Header />
            <Page className={className}>{children}</Page>
        </React.Fragment>
    );
};

export default LayoutBasic;
